export function EstateSaleMenuList() {
    const menuList = [
        {
            name: 'Antiques',
            include: '/estate-sale',
            path: '/'
        },
        {
            name: 'Art',
            include: '/art',
            path: '/',
        },
        {
            name: 'Books & Periodicals',
            include: '/art',
            path: '/',
        },
        {
            name: 'Camaras & Photo Equipment',
            include: '/art',
            path: '/',
        },
        {
            name: 'Clothing & Accessories',
            include: '/art',
            path: '/',
        },
        {
            name: 'Coins',
            include: '/art',
            path: '/',
        },
        {
            name: 'Collectibles',
            include: '/art',
        },
        {
            name: 'Craft & Hobby',
            include: '/art',
            path: '/',
        },
        {
            name: 'Electronics',
            include: '/art',
            path: '/',
        },
        {
            name: 'Farm Equipments',
            include: '/art',
            path: '/',
        },
        {
            name: 'Household Goods',
            include: '/art',
            path: '/',
        },
        {
            name: 'Jewlery',
            include: '/art',
            path: '/',
        },
        {
            name: 'Motor Vechicles',
            include: '/art',
            path: '/',
        }
    ];
    return menuList;
}