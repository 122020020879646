import { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "../MyAccountMenuList";

import './ThankYou.scss';
import store from "features/store";
import { caringtransitionslogo } from "shared/images/images";

export const ThankYouFn = (props) => {

    const [pageLoading, setPageLoading] = useState(false);
   
    useEffect(() => {
    }, []);

    const moveToEstateSale = () => {
        props.history.push('/estate-sale/list');
    }

    return (
        pageLoading ?
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> :
                <div className="thank-you d-flex flex-column p-5 w-100 text-center">
                    <div className="f-25 text-bold">Thank You! ${props.user.firstName}</div>
                    <div className="f-15">
                        {true ? <><div>You Are Now Fully Registered And Ready To Bid On CTBids</div>
                        <div className="pt-3">CTBids is made up of unique and everyday treasures, carefully curated to tell a story.</div>
                        <div className="pt-3">When you shop CTBids, you become a part of something bigger - supporting seniors and their loved ones through the process of downsizing and life's transitions, while empowering Caring Transition's owners to continue their compassionate work.</div>
                        </> :
                         <div>
                            <div>You Are Now Premier Member Ready To Bid On CTBids</div>
                            <div>With Garantted Return Assurance And Shopping Protection Included</div>
                            <div className="mt-3">CTBids is Made up of unique and everyday treasures, carefully curated to tell a story.</div>
                            <div className="mt-3">When you shop CTBids, you become a part of something bigger - supporting seniors and their loved once through the process of downsizing and life's transitions, while empowering caring Transition's owners to continue their compassionate work.</div>
                            <div className="mt-3">We will also make a contribution in your name to the Alzheimer's Foundation of America. Toghether, we will make a diffrence by Enriching Lives, Creating Hope.</div>
                        </div>}
                        <div className="pt-3">
                            <img className="logo-image" alt="Powered" src={caringtransitionslogo}></img>
                            <img src={`${process.env.PUBLIC_URL}/static/logo/CTBids_logo.png`} alt="logo" className="logo-image" />
                        </div>
                        <div className="buttons pt-5">
                            {true ? <Button variant="secondary" style={{ borderRadius: "50px" }} onClick={() => {moveToEstateSale()}}>
                                Go View CTBids Auctions
                            </Button> : 
                            <>
                            <Button variant="" style={{ backgroundColor: '#1D8296',borderRadius: '20px', color: 'white' }}>Go View CTBids Auctions</Button>
                            <Button  variant="" style={{ marginLeft: '20px', backgroundColor: '#1D8296',borderRadius: '20px', color: 'white' }} >My Premier Member Benefits</Button>
                            </>}
                        </div>
                    </div>
                </div>
    )
}

class ThankYou extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <ThankYouFn {...this.props} />
    }
}

export default connect(state => state)(ThankYou);