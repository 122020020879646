export let oldTime = 0;
export let timer = null;

export const StartTimer = (time) => {
    let startvalue = 1;
    timer = setInterval(() => {
        oldTime = time + startvalue;
        startvalue += 1;
    }, 1000);
};

export const stopTimer = () => {
    if (timer) {
        clearInterval(timer);
    }
};

export const getOldTimer = () => {
    return oldTime;
}

export const notAllow = [
    '/',
    '/my-account/bidding'
];

export const PreviousNextNotAllow = [
    '/',
    // '/advance-search/items'
]