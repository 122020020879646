import React, { Component, useEffect, useState } from "react";
import { connect } from 'react-redux';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from 'features/components/base/auth-pages/MyAccount/MyAccountMenuList';
import { logout } from "shared/settings/auth";
import axios from "axios";
import { getBuyerHeaders, getBuyersUrl } from "shared/http";
import { premierInvoiceView, membershipPdfView } from "shared/settings/apiconfig";
import moment from "moment";
import { Button, Col, Row, Table, Spinner } from "react-bootstrap";
import './PremierMemberInvoiceView.scss';

class PremierMemberInvoiceView extends Component {
  status = { 'paid': 'fa-check-circle', 'unpaid': 'fa-times-circle' };
  address = ['city', 'state', 'country', 'zipcode'];

  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id;
    this.state = {
      pageLoading: false,
      windowWidth: window.innerWidth,
      form: {
        billingto: {
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          cityState: '',
          countryZipcode: '',
          email: '',
          phonenumber: ''
        },
        billingfrom: {
          contactname: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          cityState: '',
          countryZipcode: '',
          contactemail: '',
          contactmobile: ''
        },
        downloadPdf: false,
        printPdf: false,
        invoiceId: '',
        invoicenumber: '',
        invoiceamount: '',
        invoicestatus: '',
        items: [],
        buyerid: '',
        emailsent: 0,
        type: '',
        vendor: '',
        createddate: '',
        total: '',
        invoicemessage: ''
      }
    }
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    this.getInvoiceData();
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  /* Get invoice data of the buyer premier */
  getInvoiceData = () => {
    this.setState({ pageLoading: true });
    let data = {
      "filter": [
        {
          "field": "vendor",
          "value": "CTBIDS",
          "op": "=",
          "join": "AND"
        },
        {
          "field": "type",
          "value": "Membership",
          "op": "=",
          "join": "AND"
        },
        {
          "field": "invoicestatus",
          "value": "Paid",
          "op": "=",
          "join": "AND"
        }
      ],
      "page": {
        "size": 1
      },
      "sort": [
        {
          "field": "createddate",
          "direction": "desc"
        }
      ]
    }
    axios.post(getBuyersUrl(`${premierInvoiceView}`), JSON.stringify(data), getBuyerHeaders())
      .then(async response => {
        let invoiceData = response.data.data[0];
        let data = JSON.parse(response.data.data[0].invoicedetails);
        let billingfromCityDelimiter = data.billingfrom.city && data.billingfrom.state ? ', ' : '';
        let billingfromCountryDelimiter = data.billingfrom.country && data.billingfrom.zipcode ? ', ' : '';
        let billingtoCityDelimiter = data.billingto.city && data.billingto.state ? ', ' : '';
        let billingtoCountryDelimiter = data.billingto.country && data.billingto.zipcode ? ', ' : '';
        let invoiceMessage = data.invoicemessage ?  JSON.parse(data?.invoicemessage) : ''
        this.setState({
          form: {
            ...this.state.form,
            invoiceId: invoiceData.id,
            billingto: {
              ...this.state.form.billingto,
              name: data.billingto.firstname + ' ' + data.billingto.lastname,
              address1: (data.billingto.address1 ? data.billingto.address1 + ', ' : '') + (data.billingto.address2 ? data.billingto.address2 + ', ' : ''),
              city: data.billingto.city,
              state: data.billingto.state,
              country: data.billingto.country,
              zipcode: data.billingto.zipcode,
              cityState: (data.billingto.city ? data.billingto.city : '') + (billingtoCityDelimiter) + (data.billingto.state ? data.billingto.state : ''),
              countryZipcode: (data.billingto.country ? data.billingto.country : '') + (billingtoCountryDelimiter) + (data.billingto.zipcode ? data.billingto.zipcode : ''),
              email: data.billingto.contactemail ? data.billingto.contactemail : data.billingto.email ? data.billingto.email : '',
              phonenumber: data.billingto.contactmobile ? data.billingto.contactmobile : data.billingto.mobile ? data.billingto.mobile : '',
            },
            billingfrom: {
              ...this.state.form.billingfrom,
              contactname: data.billingfrom.contactname,
              address1: (data.billingfrom.address1 ? data.billingfrom.address1 + ', ' : '') + (data.billingfrom.address2 ? data.billingfrom.address2 + ', ' : ''),
              city: data.billingfrom.city,
              state: data.billingfrom.state,
              country: data.billingfrom.country,
              zipcode: data.billingfrom.zipcode,
              cityState: (data.billingfrom.city ? data.billingfrom.city : '') + (billingfromCityDelimiter) + (data.billingfrom.state ? data.billingfrom.state : ''),
              countryZipcode: (data.billingfrom.country ? data.billingfrom.country : '') + (billingfromCountryDelimiter) + (data.billingfrom.zipcode ? data.billingfrom.zipcode : ''),
              contactemail: data.billingfrom.contactemail ? data.billingfrom.contactemail : data.billingfrom.email ? data.billingfrom.email : '',
              contactmobile: data.billingfrom.contactmobile ? data.billingfrom.contactmobile : data.billingfrom.mobile ? data.billingfrom.mobile : '',
            },
            invoicenumber: invoiceData.invoicenumber,
            invoiceamount: invoiceData.invoiceamount,
            invoicestatus: invoiceData.invoicestatus,
            items: data.items,
            buyerid: invoiceData.buyerid,
            emailsent: invoiceData.emailsent,
            type: invoiceData.type,
            vendor: invoiceData.vendor,
            createddate: invoiceData.createddate,
            total: data.items[0].grossamount,
            invoicemessage: invoiceMessage.content.body
          },
          pageLoading: false
        });
      }).catch((error) => {
        this.setState({
          pageLoading: false
        })
        if (error?.response?.status === 401) {
          store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
          logout(this.props.history, false);
        }
      })
  }

 
  membershipPdf = (value) => {
    if (value == 'download') {
      this.setState({
          downloadPdf: true
      })
  }
  else if(value == 'print'){
      this.setState({
          printPdf: true
      })
  }
    const data = {
      "data": {
        "invoiceid": [this.state.form.invoiceId],
        "type": value
      }
    }
    let config = getBuyerHeaders()
    config['responseType'] = 'blob';
    axios.post(getBuyersUrl(`${membershipPdfView}`), JSON.stringify(data), config).then((res) => {
      let values = res.data
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);

      if (value === 'print') {
        window.open(fileURL, '_blank');
        this.setState({ printPdf: false })
      }

      if (value === 'download') {
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Download.pdf';
        alink.click();
        this.setState({ downloadPdf: false})
      }

    }).catch(()=>{
      this.setState({
        downloadPdf: false,
        printPdf: false
      })
    })
  }

  navigateToPath = () => {
    this.props.history.push("/my-account/premier/premier-account");
  }

  render() {
    return (
      <div className='member-view-main'>
        <div className="d-flex">
            <div className=" w-50 justify-content-start text-left mt-3">
                <Button style={{ backgroundColor: '#A50050' }} onClick={() => this.navigateToPath()}className="m-1"><i className="fa fa-chevron-left pr-2"></i>Back</Button>
            </div>
        </div>
        {
          this.state.pageLoading ?
            <div className="loader d-flex align-items-center justify-content-center h-100">
              <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> : this.state.form.items.length > 0 ?
            <div className="member-content container h-100">
               <div className="mb-3 d-flex justify-content-end align-items-baseline text-right" >
                  <Button variant="dark" className="mr-3"  onClick={()=> this.membershipPdf('download')}>Download {this.state.downloadPdf ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}</Button>
                  <Button variant="secondary" onClick={()=> this.membershipPdf('print')}>Print {this.state.printPdf ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}</Button>
                </div>
              <div className="member-border mt-3">
                <div id="invoiceGenration" className="print" ref={this.pdfRef} >
                  <div className="p-4 border-bottom row mx-0 media-screen-900 justify-content-center">
                    <div className={` ${this.state.windowWidth > 800 ? 'd-flex text-left  flex-column wd-30' : 'd-flex text-left align-items-center flex-column wd-50'}`}>
                      <div className='d-flex  media-screen'>
                        <img className='pdf-img-800' style={{ marginLeft: "5px" }} src={`${process.env.PUBLIC_URL}/static/logo/premiermember.png`} alt="logo" />
                      </div>
                    </div>
                    <div className={`${this.state.windowWidth > 800 ? 'justify-content-end mt-3' : 'justify-content-center text-center mt-1'}`} style={{ fontSize: "20px", textAlign: "right", width: '65%' }}>
                      {this.state.form.type ?
                        <div className={` text-secondary col-xs-12`}><div style={{ fontSize: "20px", whiteSpace: 'nowrap', color: '#00263E' }}><b className='text-secondary'>Invoice Type: </b> {this.state.form.type}</div></div> : ""
                      }
                    </div>
                  </div>
                  <div className="d-flex row p-4 border-bottom mx-0">
                    <div className={`col col-xs-12 padding col-md-6`}>
                      <p><b className="text-secondary"> Billing To</b></p>
                      {Object.keys(this.state.form.billingto).map((key) =>
                        !this.address.includes(key) ?
                          key === 'name' ? <p><b>{this.state.form.billingto[key]}</b></p> :
                            key === 'phonenumber' ? this.state.form.billingto[key] ? <p style={{ color: "#1d9aa5" }} >Phone No: {this.state.form.billingto[key]}</p> : '' :
                              key === 'email' ? <p style={{ color: "#1d9aa5", wordBreak: 'break-all' }} >{this.state.form.billingto[key]}</p> : <p style={{ wordBreak: 'break-all' }}>{this.state.form.billingto[key]}</p> : ''
                      )}
                    </div>
                    <div className={`col col-xs-12 padding col-md-6`}>
                      <p><b className="text-secondary">Billing From</b></p>
                      {Object.keys(this.state.form.billingfrom).map((key) =>
                        !this.address.includes(key) ?
                          key === 'contactname' ? <p><b>{this.state.form.billingfrom[key]}</b></p> :
                            key === 'contactmobile' ? this.state.form.billingfrom[key] ? <p style={{ color: "#1d9aa5" }}>Phone No: {this.state.form.billingfrom[key]}</p> : '' :
                              key === 'contactemail' ? <p style={{ color: "#1d9aa5" }}>{this.state.form.billingfrom.contactemail} </p> : <p>{this.state.form.billingfrom[key]}</p> : ''
                      )}
                    </div>
                  </div>
                  <div className='border-bottom'>
                    <div className={` p-4 border-bottom mx-0`}>
                      <div style={{ fontSize: "20px" }}><p><b className="text-secondary">Invoice Details</b></p></div>
                    </div>
                    <div className={`${this.state.windowWidth > 800 && "d-flex row"} p-4  mx-0`} style={{ display: 'flex' }}>
                      <div className="col col-md-6 col-xs-12 padding"><div >
                        <p className={` text-secondary col-xs-12`} ><b style={{ fontSize: "20px" }}>Invoice: {this.state.form.invoicenumber}    </b>
                          {<span className={`${this.state.form?.invoicestatus?.toLowerCase() === 'paid' ? "text-success" : "text-danger"}`}>
                            <i className={`fa ${this.status[this.state.form?.invoicestatus?.toLowerCase()]} fa-lg pr-2 pt-1`} aria-hidden="true"></i>{this.state.form?.invoicestatus?.toUpperCase()}</span>}</p></div>
                      </div>
                      <div className="col col-md-6 col-xs-12 padding" style={{ textAlign: "right" }}>
                        <p><b className="text-secondary">Invoice Date :- </b>
                          <b>{moment.utc(this.state.form?.createddate).local().format('MM/DD/YYYY hh:mm A')} {moment.tz(moment.tz.guess()).zoneAbbr()}</b>
                        </p>
                      </div>
                    </div>
                    <div style={{ overflowX: "auto" }}>
                      <Table className="amountfields" responsive>
                        <thead>
                          <tr style={{ color: "#9F2241", flexWrap: "nowrap" }}>
                            <td style={{ paddingLeft: "2.5rem" }}>Plan Name</td>
                            <td>Coupon Code</td>
                            <td>Amount</td>
                            <td>Discount Amount</td>
                            <td>Tax Amount</td>
                            <td style={{ paddingRight: "2.5rem" }}>Gross Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.form.items.map((item, index) => (
                              <tr>
                                <td style={{ paddingLeft: "2.5rem" }} width="25%">{item.planname ? item.planname : "- - -"}</td>
                                <td width="15%" style={{ wordBreak: "break-word" }}>{item.couponcode ? item.couponcode : "- - -"}</td>
                                <td width="10%" style={{ whiteSpace: "nowrap" }}>{item.amount ? `$ ${parseFloat(item.amount).toFixed(2)}` : '$ 0.00'}</td>
                                <td width="15%" style={{ whiteSpace: "nowrap" }}>{item.discountamount ? `$ ${parseFloat(item.discountamount).toFixed(2)}` : '$ 0.00'}</td>
                                <td width="15%" style={{ paddingRight: "2.5rem", whiteSpace: "nowrap" }}>{item.taxamount ? `$ ${parseFloat(item.taxamount).toFixed(2)}` : '$ 0.00'}</td>
                                <td width="20%" style={{ paddingRight: "2.5rem", whiteSpace: "nowrap" }}>{item.grossamount ? `$ ${parseFloat(item.grossamount).toFixed(2)}` : '$ 0.00'}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>

                    <div className="p-5">
                      <Row className="" >
                        <Col></Col>
                        <Col className="text-right text-secondary" ><b>Total Amount</b></Col>
                        <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "36%" }}>{this.state.form.total ? `$ ${parseFloat(this.state.form.total).toFixed(2)}` : '- - -'}</b></Col>
                      </Row>
                        <Row className="mt-3">
                          <Col></Col>
                          <Col className="text-right text-secondary"><b>Payments Received </b></Col>
                          <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "36%" }}>{this.state.form.total ? `$ ${parseFloat(this.state.form.total).toFixed(2)}` : '- - -'}</b></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col></Col>
                          <Col className="text-right text-secondary"><b>Remaining Amount Due </b></Col>
                          <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "36%" }}>{ '- - -'}</b></Col>
                        </Row>
                    </div>
                  </div>
                  <div className={`d-flex flex-column pr-4 invoice-message-wraper ${this.state.windowWidth > 1800 ? 'my-5' : 'my-4'}`} style={{ paddingLeft: "4%", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: this.state.form.invoicemessage }}></div>

                </div>
              </div>
            </div> : 
            <div className="d-flex flex-row justify-content-center text-center h-100">
              <h5 className="d-flex flex-row justify-content-center text-center align-items-center">No Data Found</h5>
            </div>
        }
      </div>
    );
  }
}
export default connect(state => state)(PremierMemberInvoiceView);
