export const socialAuthCredential = {
    google: {
        clientId: "443426499185-e65r8mg7ighhs369i466p95gahuo9h41.apps.googleusercontent.com"
    },
    facebook: {
        // Buyer Dev
        //appId: "980946769343444"

        // Buyer UAT
         appId: "686951389311493"
    },
    apple: {
        clientId: "com.ctbids.uat",
        redirectURI: "https://buyeruat.ctbids.com"
    }
};
