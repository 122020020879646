import { cardTypes } from "./cardFormats";

const cardNames = {
    VISA: "visa",
    MASTERCARD: "mastercard",
    AMERICAN_EXPRESS: "american-express",
    DINERS_CLUB: "diners-club",
    DISCOVER: "discover",
    JCB: "jcb",
    UNIONPAY: "unionpay",
    MAESTRO: "maestro",
    ELO: "elo",
    MIR: "mir",
    HIPER: "hiper",
    HIPERCARD: "hipercard",
};

const ORIGINAL_TEST_ORDER = [
    cardNames.VISA,
    cardNames.MASTERCARD,
    cardNames.AMERICAN_EXPRESS,
    cardNames.DINERS_CLUB,
    cardNames.DISCOVER,
    cardNames.JCB,
    cardNames.UNIONPAY,
    cardNames.MAESTRO,
    cardNames.ELO,
    cardNames.MIR,
    cardNames.HIPER,
    cardNames.HIPERCARD,
];

const testOrder = [...ORIGINAL_TEST_ORDER];

const luhn10 = (identifier) => {
    var sum = 0;
    var alt = false;
    var i = identifier.length - 1;
    var num;

    while (i >= 0) {
        num = parseInt(identifier.charAt(i), 10);

        if (alt) {
            num *= 2;
            if (num > 9) {
                num = (num % 10) + 1; // eslint-disable-line no-extra-parens
            }
        }

        alt = !alt;

        sum += num;

        i--;
    }

    return sum % 10 === 0;
}

function verification(card, isPotentiallyValid, isValid) {
    return {
        card,
        isPotentiallyValid,
        isValid,
    };
}

export const findCard = (value, options = {}) => {
    let isPotentiallyValid, isValid, maxLength;

    if (typeof value !== "string" && typeof value !== "number") {
        return verification(null, false, false);
    }

    const testCardValue = String(value).replace(/-|\s/g, "");

    if (!/^\d*$/.test(testCardValue)) {
        return verification(null, false, false);
    }

    const potentialTypes = getCardTypes(testCardValue);

    if (potentialTypes.length === 0) {
        return verification(null, false, false);
    } else if (potentialTypes.length !== 1) {
        return verification(null, true, false);
    }

    const cardType = potentialTypes[0];

    if (options.maxLength && testCardValue.length > options.maxLength) {
        return verification(cardType, false, false);
    }

    isValid = luhn10(testCardValue);

    maxLength = Math.max.apply(null, cardType.lengths);
    if (options.maxLength) {
        maxLength = Math.min(options.maxLength, maxLength);
    }

    for (let i = 0; i < cardType.lengths.length; i++) {
        if (cardType.lengths[i] === testCardValue.length) {
            isPotentiallyValid = testCardValue.length < maxLength || isValid;

            return verification(cardType, isPotentiallyValid, isValid);
        }
    }

    return verification(cardType, testCardValue.length < maxLength, false);
};

const getCardTypes = (cardNumber) => {
    const results = [];

    testOrder.forEach((cardType) => {
        const cardConfiguration = cardTypes[cardType];

        addMatchingCardsToResults(cardNumber, cardConfiguration, results);
    });

    const bestMatch = findBestMatch(results);
    if (bestMatch) {
        return [bestMatch];
    }

    return results;
};

const addMatchingCardsToResults = (cardNumber, cardConfiguration, results) => {
    let i, patternLength;

    for (i = 0; i < cardConfiguration.patterns.length; i++) {
        const pattern = cardConfiguration.patterns[i];

        if (!matches(cardNumber, pattern)) {
            continue;
        }

        const clonedCardConfiguration = { ...cardConfiguration };

        if (Array.isArray(pattern)) {
            patternLength = String(pattern[0]).length;
        } else {
            patternLength = String(pattern).length;
        }

        if (cardNumber.length >= patternLength) {
            clonedCardConfiguration.matchStrength = patternLength;
        }

        results.push(clonedCardConfiguration);
        break;
    }
}

function matchesRange(cardNumber, min, max) {
    const maxLengthToCheck = String(min).length;
    const substr = cardNumber.substr(0, maxLengthToCheck);
    const integerRepresentationOfCardNumber = parseInt(substr, 10);

    min = parseInt(String(min).substr(0, substr.length), 10);
    max = parseInt(String(max).substr(0, substr.length), 10);

    return (
        integerRepresentationOfCardNumber >= min &&
        integerRepresentationOfCardNumber <= max
    );
}

function matchesPattern(cardNumber, pattern) {
    pattern = String(pattern);

    return (
        pattern.substring(0, cardNumber.length) ===
        cardNumber.substring(0, pattern.length)
    );
}

function matches(cardNumber, pattern) {
    if (Array.isArray(pattern)) {
        return matchesRange(cardNumber, pattern[0], pattern[1]);
    }

    return matchesPattern(cardNumber, pattern);
}

function hasEnoughResultsToDetermineBestMatch(results) {
    const numberOfResultsWithMaxStrengthProperty = results.filter((result) => result.matchStrength).length;

    /*
     * if all possible results have a maxStrength property that means the card
     * number is sufficiently long enough to determine conclusively what the card
     * type is
     * */
    return (
        numberOfResultsWithMaxStrengthProperty > 0 &&
        numberOfResultsWithMaxStrengthProperty === results.length
    );
}

function findBestMatch(results) {
    if (!hasEnoughResultsToDetermineBestMatch(results)) {
        return null;
    }

    return results.reduce((bestMatch, result) => {
        if (!bestMatch) {
            return result;
        }

        /*
         * If the current best match pattern is less specific than this result, set
         * the result as the new best match
         * */
        if (Number(bestMatch.matchStrength) < Number(result.matchStrength)) {
            return result;
        }

        return bestMatch;
    });
}

export const addSpacesForCardNumber = (previousNumber, currentNumber, gaps) => {
    let value = currentNumber.replace(/ /g, "");
    let newValue = "";
    if (previousNumber.slice(-1) != " "  && Array.isArray(gaps)) {
        let numArr = value.split("");
        for (let index in numArr) {
            newValue += numArr[index];
            if (gaps.includes((Number(index) + 1))) {
                newValue += " ";
            }
        }
    } else {
        newValue = currentNumber;
    }

    return newValue;
};