import { expirationMonth } from "./expirationMonth";
import { expirationYear } from "./expirationYear";

const DEFAULT_VALID_NUMBER_OF_YEARS_IN_THE_FUTURE = 20;

function verification(isValid, isPotentiallyValid, month, year) {
    return {
        isValid,
        isPotentiallyValid,
        month,
        year,
    };
}

const isArray =
    Array.isArray ||
    function (arg) {
        return Object.prototype.toString.call(arg) === "[object Array]";
    };

function getNumberOfMonthDigitsInDateString(dateString) {
    const firstCharacter = Number(dateString[0]);
    let assumedYear;

    /*
      if the first character in the string starts with `0`,
      we know that the month will be 2 digits.
  
      '0122' => {month: '01', year: '22'}
    */
    if (firstCharacter === 0) {
        return 2;
    }

    /*
      if the first character in the string starts with
      number greater than 1, it must be a 1 digit month
  
      '322' => {month: '3', year: '22'}
    */
    if (firstCharacter > 1) {
        return 1;
    }

    /*
      if the first 2 characters make up a number between
      13-19, we know that the month portion must be 1
  
      '139' => {month: '1', year: '39'}
    */
    if (firstCharacter === 1 && Number(dateString[1]) > 2) {
        return 1;
    }

    /*
      if the first 2 characters make up a number between
      10-12, we check if the year portion would be considered
      valid if we assumed that the month was 1. If it is
      not potentially valid, we assume the month must have
      2 digits.
  
      '109' => {month: '10', year: '9'}
      '120' => {month: '1', year: '20'} // when checked in the year 2019
      '120' => {month: '12', year: '0'} // when checked in the year 2021
    */
    if (firstCharacter === 1) {
        assumedYear = dateString.substr(1);

        return expirationYear(assumedYear).isPotentiallyValid ? 1 : 2;
    }

    /*
      If the length of the value is exactly 5 characters,
      we assume a full year was passed in, meaning the remaining
      single leading digit must be the month value.
  
      '12202' => {month: '1', year: '2202'}
    */
    if (dateString.length === 5) {
        return 1;
    }

    /*
      If the length of the value is more than five characters,
      we assume a full year was passed in addition to the month
      and therefore the month portion must be 2 digits.
  
      '112020' => {month: '11', year: '2020'}
    */
    if (dateString.length > 5) {
        return 2;
    }

    /*
      By default, the month value is the first value
    */
    return 1;
}

function parseDate(datestring) {
    let date;

    if (/^\d{4}-\d{1,2}$/.test(datestring)) {
        date = datestring.split("-").reverse();
    } else if (/\//.test(datestring)) {
        date = datestring.split(/\s*\/\s*/g);
    } else if (/\s/.test(datestring)) {
        date = datestring.split(/ +/g);
    }

    if (isArray(date)) {
        return {
            month: date[0] || "",
            year: date.slice(1).join(),
        };
    }

    const numberOfDigitsInMonth = getNumberOfMonthDigitsInDateString(datestring);
    const month = datestring.substr(0, numberOfDigitsInMonth);

    return {
        month,
        year: datestring.substr(month.length),
    };
}

export const expirationDate = (value, maxElapsedYear = DEFAULT_VALID_NUMBER_OF_YEARS_IN_THE_FUTURE) => {
    let date;

    if (typeof value === "string") {
        value = value.replace(/^(\d\d) (\d\d(\d\d)?)$/, "$1/$2");
        date = parseDate(String(value));
    } else if (value !== null && typeof value === "object") {
        const fullDate = { ...value };
        date = {
            month: String(fullDate.month),
            year: String(fullDate.year),
        };
    } else {
        return verification(false, false, null, null);
    }

    const monthValid = expirationMonth(date.month);
    const yearValid = expirationYear(date.year, maxElapsedYear);

    if (monthValid.isValid) {
        if (yearValid.isCurrentYear) {
            const isValidForThisYear = monthValid.isValidForThisYear;

            return verification(
                isValidForThisYear,
                isValidForThisYear,
                date.month,
                date.year,
            );
        }

        if (yearValid.isValid) {
            return verification(true, true, date.month, date.year);
        }
    }

    if (monthValid.isPotentiallyValid && yearValid.isPotentiallyValid) {
        return verification(false, true, null, null);
    }

    return verification(false, false, null, null);
}

export const addSpacesForExpirationDate = (previousValue, currentValue) => {
    let finalValue = "";

    // Getting last character of the expiry date
    let lastChar = previousValue.slice(-1);

    // User trying to remove the expiry date
    if (lastChar === " " && currentValue.length < previousValue.length) {
        finalValue = "";
    } else if (!lastChar || /^\d+$/.test(lastChar)) {
        if (currentValue[0] >= 2 && currentValue[0] <= 9) {
            finalValue = `0${currentValue[0]}` + ` / `;
        } else if (currentValue.length === 2) {
            finalValue = `${currentValue}` + ` / `;
        } else {
            finalValue = currentValue;
        }
    } else {
        finalValue = currentValue;
    }

    return finalValue;
};