import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { ourstory, ctbidslogo, caringtransitionslogo, earthImg, heartImg } from 'shared/images/images';
import { Form } from 'react-bootstrap';
import "./OurStory.scss";
import { Helmet } from 'react-helmet';

class OurStory extends Component {
    constructor(props) {
        super(props);

        this.state = {


        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }
    openCaringTransitions = () => {
        window.open('https://www.caringtransitions.com/', '_blank');
    }
    navigate = (flag) => {
        this.props.history.push(flag);
    }



    render() {
        return (
            <div className="our-story" style={{ width: '100%', overflow: 'overlay' }}>
                <Helmet>
                    <title>CTBids | Our Story</title>
                </Helmet>
                <div>
                    <img src={ourstory} className='our-story-img' alt="ourstory" style={{ width: '100%' }} />
                </div>
                <div className='mt-5 mb-3 image-container' >

                    <div className='cursor-pointer' style={{ cursor: 'pointer' }} onClick={this.openCaringTransitions}>
                        <img src={caringtransitionslogo} className='ct-image' alt="" />
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => this.navigate('/')}>
                        <img src={ctbidslogo} className='ctbids-logo' alt="" />
                    </div>

                </div>
                <div style={{ padding: '20px'}}>
                    <div className='mb-3 our-story-message' >
                        CTBids grew from an idea over 10 years ago by Caring Transitions owners all across the country. These owners are carefully managing the treasures
                        and everyday household items for their clients as they downsize or transition to new homes. These items have quality, value, and a history that are a
                        perfect fit for someone else. Our mission was to create a platform where these estate sales are brought to you online. Instead of set pricing, bidding
                        starts at $1. This process allows our bidders to find unique and everyday treasures for a great value. In addition, the repurposing of these belongings
                        will have a positive and lasting impact on the world we all share.
                    </div>
                    <div className='mb-3 our-story-message' >
                        Caring Transitions is the nation’s largest and most trusted organization specializing in downsizing,
                        relocation, estate sales, decluttering, home cleanouts, online auctions and other services.
                        There are over 325 locations nationwide that are total solution providers for all of life’s transitions.
                        Embrace new beginnings with confidence by learning more at CaringTransitions.com.
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className='img-content' >
                            <img src={earthImg} alt="" className='inside-image' />
                        </div>
                        <div style={{ float: 'right', }} className='text-content'>
                            <div className='mb-3 our-story-message' >
                                Transitions, by nature, can have profound effects on the environment, and we’re committed to mitigating these impacts.
                                We care about the environment, and the necessity to repurpose household treasures.
                            </div>
                            <div className='mb-3 our-story-message berry-color' >
                                Simply put, we care about transitions in our communities
                            </div>
                            <div className='mb-3 our-story-message' >
                                The transition of a senior from a large home to assisted living. The transition of a home filled with years of memories to a home that is empty and ready to be sold. The transition of beloved possessions to new owners.
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className='img-content'>
                            <img src={heartImg} alt="" className='inside-image' />

                        </div>
                        <div style={{ float: 'right', width: '85%' }} className='text-content'>
                            <div className='mb-3 our-story-message' >
                                CTBids gives us the ability to connect family heirlooms made with yesterday’s craftsmanship
                                with new owners who will give them new LIFE.
                            </div>
                            <div className='mb-3 our-story-message  berry-color'>
                                Why do our bidders love CTBids?
                            </div>
                            <div className='mb-3 our-story-message' >
                                Similar to traditional estate sales, our online bidders may find anything from precious jewels to fine artwork,
                                from copper pots to dorm room furniture, from rare books to common shop tools as they look through each online estate sale.
                                From unique treasures to everyday items, you will always find something interesting on CTBids! Everyone wins,
                                including the environment!
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex'}} className='mt-5'>
                    <div style={{backgroundColor: '#A50050', padding:'5px',width:'16.6%'}}></div>
                    <div style={{backgroundColor: '#CD545B', padding:'5px',width:'16.6%' }}></div>
                    <div style={{backgroundColor: '#1D8296', padding:'5px',width:'16.6%' }}></div>
                    <div style={{backgroundColor: '#71C5E8', padding:'5px',width:'16.6%'  }}></div>
                    <div style={{backgroundColor: '#D0DF00', padding:'5px',width:'16.6%'  }}></div>
                    <div style={{backgroundColor: '#BBC7D6', padding:'5px',width:'17%'  }}></div>
                </div>
                <div className='how-it-works '>
                    How It Works
                </div>
                <div style={{ padding: '20px' }}>

                    <div className='mb-3 mt-3 our-story-message' >
                        As a CTBids customer, you will enjoy the unique shopping experience whether you are hunting for a
                        specific item or just browsing through countless estates online and discovering unique and everyday
                        treasures. Take a virtual peek into a stylish farmhouse, browse through an art lover's gallery, find a
                        great (sometimes often new) cooking appliance or even shop for a car.
                    </div>
                    <div className='mb-3 our-story-message berry-bold' >
                        Becoming a registered bidder is EASY
                    </div>
                    <div className='mb-3 our-story-message' >
                        To contact you if you win, we require you to verify your account with a phone number.
                        We can send your verification code by text or call you with the verification number for you to complete your registration.
                        You can get a head start on registration by using Facebook or Google, or by filling in contact details into our form.
                        After you have registered, you will be greeted each time you return to the site with a personal dashboard that keeps you
                        posted on your activity. Stay informed of how many items you have won, your current bidding status, invoicing and more! When you win,
                        you will have the option to pay with the payment method on file.
                    </div>
                    <div className='mb-3 our-story-message' >
                        We are not like other sites. CTBids is powered by Caring Transitions as part of a total solution for our clients.
                        Caring Transitions owners are the sellers, working with local clients who have a need to downsize, liquidate and
                        sell treasures they no longer have use for. Bidding starts at $1, and most sales run for 7 to 14 days.
                    </div>
                    <div className='mb-3 our-story-message' >
                        We keep it simple and fair. We offer proxy bidding, allowing you to let the system place bids on your behalf at the lowest possible increments
                        over competing bids. Simply enter your maximum bid (the highest amount you are willing to pay). The goal of proxy bidding is for
                        you to win at the lowest possible price. You will be notified by text and email if you are outbid so you can increase your maximum bid if
                        you wish to do so. Our site uses soft close times so that you have more time to place a bid as the sale begins to close.
                    </div>
                    <div className='mb-3 our-story-message' >Pick up locally or request shipping. With over 200 locations across the country many
                        items can be picked up locally. If you find a treasure and want it shipped, you can contact the seller for shipping options.
                    </div>
                    <div className='mb-3 our-story-message berry-bold' style={{ textAlign: 'center' }}>Help 24/7! If you encounter any problem using the site, there is always a human available.</div>
                    <div className='mb-3 our-story-message berry-bold' style={{ textAlign: 'center' }} >If you have questions, just call 1-844-4BIDHELP</div>

                </div>
            </div>
        );
    }
}

export default connect(state => state)(OurStory);