import moment from "moment";
import React, { useEffect, useState, Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { sneakpreview, timetable } from "shared/images/images";
import { ItemsService, LocationService, SalesService } from "shared/services";
import store from 'features/store';
import { ToggleSideBar, MyAccountMenuList, removeSideBar } from "../base/auth-pages/MyAccount/MyAccountMenuList";
import { isAuthorized, logout } from "shared/settings/auth";
import VideoThumbnail from "shared/video-thumbnail/VideoThumbnail";
import './auctionsp.scss';

function AuctionSneakPeekSaleItemfn(props) {
    const [imageList, setImageList] = useState([]);
    const [item, setitem] = useState(props?.history?.location?.state?.item);
    const [saleID, setsaleID] = useState(props?.match?.params?.saleId);
    const [sale, setsale] = useState((props?.history?.location?.state?.sale));
    const [locImg, setlocImg] = useState("");
    const [locationDetails, setlocationDetails] = useState({});
    const [watch, setwatch] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [videoThumbnails, setVideoThumbnails] = useState({});
    const [itemID, setItemID] = useState(props?.match?.params?.itemId);
    const [disableWatch, setDisableWatch] = useState(false);
    const [activeMediaIndex, setActiveMediaIndex] = useState('');

    useEffect(() => {
        loading();
        return () => {

        }
    }, [])

    const loading = async () => {
        await itemList();
        await getLocData();
        setPageLoading(false);
    }

    const getLocData = async () => {
        await LocationService.getLocationDetails(sale?.locationid, { data: {} }).then(
            location => {
                setlocationDetails(location.data.data[0])

                // google analytics config for item list page

            }
        ).catch((error) => {
            // TODO: Error message handle
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        });
        const locationImageData = { "filter": [{ "field": "locationid", "value": sale?.locationid, "op": "=", "join": "AND" }] };
        await LocationService.getLocationImagesList(locationImageData).then(
            locationImage => {
                setlocImg(locationImage?.data?.data)
            }
        ).catch((error) => {
            // TODO: Error message handle
        });
    }
    
    const getItemImages = async (id) => {
        const imageListPostData = {
            "filter": [{ "field": "itemid", "value": id, "op": "=", "join": "AND" }],
            "field": ["id", "name", "type", "url", "itemid", "isdisplayimage", "displayorder", "thumbnailurl"],
            "sort": [{ "field": "displayorder", "direction": "asc" }],
            "page": { "size": 1000 }
        };

        await ItemsService.getItemImagesList(imageListPostData).then(resp => {
            setImageList(resp?.data?.data)
        }).catch(error => {
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        });

    }
    const itemList = async () => {
        await ItemsService.getMemberItemDetail(itemID, saleID).then(async(resp) => {
        
            resp = resp?.data?.data[0]
            setitem(resp)
            await getItemImages(resp?.id)
            await getWatchList(resp?.id)
        }).catch(error => {
        });

    }
    const getWatchList = async (id) => {
        if (isAuthorized()) {
            const data = {
                "sort": [{ "field": "id", "direction": "desc" }],
                "filter": [{ "field": "itemid", "value": [[id]], "op": "IN", "join": "AND" }],
                "field": ["itemid"],
                "page": { "size": 2 }
            };
            await ItemsService.getWatchListItem(data).then(
                response => {
                    const watch = response.data.data;
                    setwatch(watch.length > 0)
                }
            ).catch(error => {
                // this.emitData();
                if (error?.status === 401) {
                    store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                    logout(props.history, false);
                }
            });
        }
    }
    const watching = () => {
        setDisableWatch(true);
        if (!isAuthorized()) {
            return
        }
        if (watch) {
            const removeData = {
                "data": {
                    "itemIds": [item?.id]
                }
            };
            ItemsService.removeFromWatch(removeData).then(
                response => {
                    setwatch(false);
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        } else {
            const addData = {
                "data": [
                    {
                        "itemid": item?.id
                    }
                ]
            }
            ItemsService.addToWatch(addData).then(
                response => {
                    setwatch(true);
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        }

    }

    const moreSaleForLocation = () => {
        const location = locationDetails;
        if (location.id) {
            const locationUrl = location.locationcode ? `/estate-sales/${location.locationstatecode}/${location.locationcode}` : `/estate-sales/${location.locationstatecode}`;
            props.history.push(locationUrl);
        }
    }

    const videoPlayToggle = (index) => {
        setActiveMediaIndex(index);
    }

    if (pageLoading)
        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div>)
    return (
        <div className="asp" style={{ height: "100%" }}>
              {props.user.suspensionstatus === "ALL" ?
                <div className="w-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
                    You are currently suspended from bidding on CTBids. Please reach out to <a  style={{ color: '#A50050' }} href="tel:(844) 4-BID-HELP" >(844) 4-BID-HELP</a> or email <a style={{ color: '#A50050' }} href="mailto:ctbidshelp@caringtransitions.com">ctbidshelp@caringtransitions.com</a> if you have any questions.
                    </div>
                </div> :
            <div className="aspsi_container w-100" style={{ overflow: 'scroll', height: "100%" }}>
                <div className="asp_container" >
                    <div className="center">
                        <img className="timetable" src={timetable} />
                        <div className="title d-flex justify-content-center flex-column">
                            <h1 className="font-weight-bold">Auction Sneak Peek</h1>
                        </div>
                    </div>
                    <div className="header">
                        <div style={{ width: "100%" }}>
                            <div className="head_cont title_view">
                                <h4 className="d-flex"><div className="font-weight-bold no-wrap">Item : </div>&nbsp;{item?.title}</h4>
                                <h4 className="d-flex"><div className="font-weight-bold no-wrap">Category : </div>&nbsp;{item?.category}</h4>
                                <h4>Live Auction tentatively starts {moment(item?.salestarttime).format("MMMM DD")}</h4>
                            </div>
                        </div>
                        <div className="item_left" style={{ marginTop: "35px" }}>
                            {/* <div className="selflogo">
                                <img className="logobid" src={sneakpreview} />
                            </div> */}
                            {locationDetails && <div class="caring-transition">
                                <div class="l_header">Caring Transitions Location</div>
                                <div class="body-content">
                                    <div class="user-details d-flex align-items-center clickable">
                                        {
                                            locImg.length > 0 && React.Children.toArray(locImg.filter(x => x.name === 'profile').map((x, index) =>
                                                <img src={x.url} className="phone-icon" alt="phone-icons"></img>
                                            ))
                                        }
                                        <div class="name clickable"><div>{locationDetails?.contactname}</div>
                                            <div>
                                                <div class="light-color city-alignment city-new-site-alignment">{locationDetails?.city}</div>
                                                <div class="website city-website-alignment city-new-site-alignment clickable"
                                                    onClick={() => {
                                                        window.open(locationDetails?.website, '');
                                                    }}>
                                                    <i class="fas fa-globe"></i>
                                                    <span>Visit Site </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-sale" onClick={() => moreSaleForLocation()}>
                                        <i className="fas fa-link"></i> <span className="clickable click-more-sale">Please click to check for more sales</span>
                                    </div>
                                    <div className="mt-3 navy-text">
                                    We know you're excited about upcoming auctions, we are too. Until this auction is live, we will not be able to answer any questions or provide additional information regarding these items. Auction dates and items are subject to change.
                                    </div>

                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="d-flex mobile-resp-item">
                        <button className={`clickable watch-button-sp ${watch ? "itemWatch" : ""}` } onClick={() => { watching(); }} disabled={disableWatch}>
                            <div>Watch</div>
                            <i className=" fa fa-eye" />
                        </button>
                        {saleID != undefined && <button className="sneak-btn clickable resp-mr-10" onClick={() => {
                            props.history.push({ pathname: `/my-account/premier/auction-sneak-peak-sale/${saleID}`, state: { sale: sale, itemDetails: item }});
                        }}>
                            <div>More Items From This Auction</div>
                        </button>}
                        <button className="sneak-btn clickable" onClick={() => {
                            props.history.push({ pathname: "/my-account/premier/auction-sneak-peak" });
                        }}>
                            <div>Return To Sneak Peek Listings</div>
                        </button>
                    </div>

                    <Row xs={1} sm={2} md={2}>
                        {imageList?.map((media, index) => {
                            let url = media.thumbnailurl ? media.thumbnailurl : media.url
                            return (
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <Col key={`items_${index}`}>
                                        <div key={`image_${index}`} className="image d-flex align-items-center justify-content-center" style={{ height: "407px", border: "2px solid #d0de03" }}>
                                            {
                                                media.type === 'image' ?
                                                    <div className="image-card">
                                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                                                            <img src={url} className="select sneak-image-wrapper" alt="item"></img>
                                                        </div>
                                                    </div> :
                                                    activeMediaIndex == index ?
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <video disablePictureInPicture src={media.url} controls controlsList="nodownload"
                                                                autoPlay="autoplay" style={{ maxHeight: '350px', width: "100%" }} webkit-playsinline playsinline></video>
                                                        </div>
                                                        : <VideoThumbnail
                                                            videoUrl={media.url}
                                                            thumbnailHandler={(thumbnail) => {
                                                                setVideoThumbnails({ 'videoThumbnails': { videoThumbnails, [media.id]: thumbnail } })
                                                            }}
                                                            onClick={() => { videoPlayToggle(index) }}
                                                        />
                                            }
                                        </div>
                                    </Col>
                                </div>
                            )
                        })}
                    </Row>
                </div>
                <div className="d-flex flex-row justify-content-center mrbt-2 sneak-item-resp">
                    <img className="logobid" src={sneakpreview} />
                </div>
            </div>}
        </div>
    )
}
class AuctionSneakPeekSaleItem extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <AuctionSneakPeekSaleItemfn {...this.props} />
    }
}

export default connect(state => state)(AuctionSneakPeekSaleItem);