import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import store from 'features/store';
import { Button, Form, Row, Col, } from 'react-bootstrap';

import { MyAccountMenuList, ToggleSideBar } from '../MyAccountMenuList';
import { contractList } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';


class Mir extends Component {
    config;
    constructor(props) {
        super(props);
        this.dataTable = createRef();
        this.itemSearch = {
            itemtitle: { field: 'itemtitle', value: '', op: 'LIKE', join: 'AND' },
            SKU: { field: 'SKU', value: '', op: 'LIKE', join: 'AND' },
            contractid: { field: 'contractid', value: '', op: 'LIKE', join: 'AND' },
            type: {field: "type", value: "RA",op: "=",join: "AND"}
        }
        this.state = {
            searchData: true,
            pageLoading: false,
            filterSearch: [{
                "field": "type",
                "value": "RA",
                "op": "=",
                "join": "AND"
            }],
            search : {
                ...this.itemSearch
            },
            tableData:[],
        }
       
        this.config = {
            url: `${contractList}`,
            params: {},
            columns: [
                { name: '', property: 'id', columnType: 'custom', actions: ['view'], customHeader: '',width: '110px', customColumn: this.customViewButton },
                { name: 'Item Title', property: 'itemtitle', sort: false, width: '250px'},
                { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '150px', columnType: 'custom', customColumn: this.customInvoiceView  },
                { name: 'SKU', property: 'SKU', sort: false, width: '130px' },
                { name: 'Contract ID', property: 'contractid', sort: false, width: '130px' },
                { name: 'Contract Status', property: 'contractstatus', sort: false, width: '130px' },
                { name: 'Claim ID', property: 'claimid', sort: false, width: '130px' },
                { name: 'Claim Status', property: 'claimstatus', sort: false, width: '150px' },
            ],
            field: ["id", "orderid", "contractid", "contractstatus", "claimid", "claimstatus", "itemtitle", "SKU", "category", "contractresponse", "invoicenumber", "buyeremail","invoiceid","updateddate","type"],
            pagination: true,
            
            service: 'BUYER',
            sort: [{ field: 'updateddate', direction: 'desc' }],
        };
        if (props.location.state && props?.location?.state?.datatable?.datatable  && props?.location?.state?.datatable?.datatable?.search) {
            let tempSearch = { ...this.itemSearch };
            const search = [...props?.location?.state?.datatable?.datatable?.search];

            search.forEach(element => {
                let temp = { ...element };
                if (typeof temp['value'] === 'string') {
                    temp['value'] = temp['value'].replace(/(^\*)|(\*$)/g, '');
                }
                tempSearch[temp['field']] = temp;
            });

            this.state['search'] = tempSearch;
            this.state['searchData'] = true;
          
        }
    }

    componentDidMount() {
        if (!this.props.user.id) {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        this.windowResizeHandle();
        window.addEventListener('resize', this.windowResizeHandle);
        window.history.pushState(null, null, window.location.pathname);
    }

    componentDidUpdate() {
        window.addEventListener('resize', this.windowResizeHandle);
    }

    onInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
         
        })
    }
    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event,
        });
    }
    resetState = () => {
        this.setState({
            ...this.state,
            search: this.itemSearch,
            filterSearch: this.itemSearch,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });

    }
    enableSearch = () => {
        let filter = {};
        filter = {
            itemtitle: { ...this.state.search.itemtitle, value: this.state.search.itemtitle.value ? '*' + this.state.search.itemtitle.value + '*' : '' },
            SKU: { ...this.state.search.SKU, value: this.state.search.SKU.value ? '*' + this.state.search.SKU.value + '*' : '' },
            contractid: { ...this.state.search.contractid, value: this.state.search.contractid.value ? '*' + this.state.search.contractid.value + '*' : '' },
            RA : {field: "type",value: "RA",op: "=",join: "AND"}
        }
        this.setState({
            ...this.state,
            filterSearch: filter,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
          
        }

    }

    customViewButton = (values) => {
        const contractResponse = JSON.parse(values.contractresponse);
        const email = contractResponse[0].data.customer.email;
        return (
            <Button
                className='paid-button'
                onClick={() => values['seelviewurl'] ? window.open(values['seelviewurl'], '_blank') : ''} aria-label='invoice view'>
                View
            </Button>
        );
    }
    customInvoiceView = (values) => {
        return (<span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.invoiceview(values.invoiceid)}>{values['invoicenumber'] ? values['invoicenumber'] : '- - -'}</span>);
    }
    invoiceview = (invoiceid) => {
        let data = {}
        data['datatable'] = this.state.tableData;
        data['mirstatus'] = true
        this.props.history.push({ pathname :`/my-account/invoice/ra/${invoiceid}/view`, state: data})
    }


    windowResizeHandle = () => {
        ToggleSideBar();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeHandle);
        store.dispatch({ type: 'SET_SIDEBAR', payload: false });

    }

    render() {
        return (
            <div className="invoice-list-main top-border">
                {this.props.user.id ? <>
                    {
                        this.state.pageLoading ?
                            <div className="loader d-flex align-items-center justify-content-center">
                                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
                            </div> :
                            <>
                                <div>
                                    <div className="sale-search" style={{ backgroundColor: '#BBC7D6' }}>
                                            <Form className="sales-list-font">
                                                <div className="sales-search-container p-3">
                                                    <Row >
                                                        <Col>
                                                            <label>Title</label>
                                                            <Form.Control onKeyDown={this._handleKeyDown} type="text"  value={this.state.search.itemtitle.value} name="itemtitle" onChange={(event) => this.onInputChange(event)} />
                                                        </Col>

                                                        <Col>
                                                            <label>SKU</label>
                                                            <Form.Control onKeyDown={this._handleKeyDown} type="text" name="SKU"  value={this.state.search.SKU.value} onChange={(event) => this.onInputChange(event)} />
                                                        </Col>
                                                        <Col>
                                                            <label style={{ whiteSpace: 'nowrap' }}>Contract ID</label>
                                                            <Form.Control onKeyDown={this._handleKeyDown} type="text" name="contractid" value={this.state.search.contractid.value} onChange={(event) => this.onInputChange(event)} />
                                                        </Col>
                                                        <Col>
                                                        <div className="buttonClass" style={{ marginTop: '25px' }}>
                                                                <Button  style={{ whiteSpace: 'nowrap' }} className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                                                <Button style={{ backgroundColor: '#00263E', color: 'white' }} onClick={() => this.enableSearch()}>Search</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </div>
                                </div>
                                <div className="main-content" style={{ position: "absolute", width: '100%' }}>

                                    <div className="w-100">
                                        <Datatable  {...this.props}  config={{ ...this.config }} onDataUpdate={this.updateTableData} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} />
                                    </div>
                                </div>
                            </>
                    }
                </> : ''}
               
            </div>
        );
    }
}

export default connect(state => state)(Mir);