import { Component } from 'react';
import { Button, Row, Col, Form, Spinner, Modal } from 'react-bootstrap';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar } from '../MyAccountMenuList';
import { getBuyerHeaders, getMembershipUrl,getBuyersUrl } from 'shared/http';
import { connect } from 'react-redux';
import axios from 'axios';
import { applyCouponCode, memberShipAdd, membershipPlan, vaultCardList } from 'shared/settings/apiconfig';
import moment from 'moment';
import './VipPlan.scss';
import { setUser } from 'shared/settings/auth';
import { pmAutoNavigateToNextScreen } from 'shared/utils/utils';

class VipPlan extends Component {
    previousProfile = this.props.user;

    constructor(props) {
        super(props);

        this.state = {
            payPalID: '',
            cardDegit: '',
            membership: {
                planName: '',
                planID:'',
                startDate: '',
                endDate: '',
                planCost: '',
                couponCode: '',
                planTax: '',
                taxAmount: '',
                totalAmount: '',
                duration: '',
                discountPercentage: '',
                discountAmount: '',
                disclaimerMessage: ''
            },
            loading: false,
            couponLoading: false,
            successModal: false,
            errorMessage: '',
            couponErrorMessage: '',
            couponSuccessMessage: '',
            disableCouponInput: false,
            termsChecked: false,
            planLoading: false,
            couponInvalid: false
        };
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        if (!this.props.user.id) {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
        else if(this.props.user.membershipCompleted || this.props.user.suspensionstatus == 'ALL' || !this.props.user.paymentcompleted || !this.props.user.profilecompleted ){
            this.props.history.push('/')
        }
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        this.getPlanDetails();
    }

    goToPlan = () => {
        this.setState({
            loading: false,
            couponLoading: false,
            successModal: false,
            errorMessage: '',
            couponErrorMessage: '',
            couponSuccessMessage: '',
            termsChecked: false
        });
        pmAutoNavigateToNextScreen(this.props, this.previousProfile, null, 'membershipCompleted', null, false, false, true);
    }

    disclaimerFn = (disclaimer) => {
        const parts = disclaimer.split(/(HREFSTART#.*?#.*?HREFEND)/);
        return parts.map((part, index) => {
            if (part.startsWith('HREFSTART')) {
                const [linkText, path] = part.slice(10, -8).split('#');
                return linkText.includes('CTBids Premier Terms') ? (
                    <a key={index} className="disclaimer-link" href={path} target="_blank" rel="noopener noreferrer">
                        {linkText}
                    </a>
                ) : (
                    <span key={index} className="disclaimer-link" onClick={() => this.props.history.push({ pathname: path })}>
                        {linkText}
                    </span>
                );
            }
            return part;
        });
    }
    getPlanDetails = async () => {
        let data = {
            "filter": []
        };
        this.setState({
            planLoading: true
        })
        await this.getPaymentDetail()
        axios.post(getBuyersUrl(membershipPlan), JSON.stringify(data), getBuyerHeaders())
            .then(response => {
                let membershipData = response.data.data[0];
                let amountBreakupData = membershipData.amountbreakup;
                let startDate = moment().utc().format('MM/DD/YYYY');
                let endDate = moment().utc().add(membershipData.duration, 'months').format('MM/DD/YYYY');

                this.setState({
                    membership: {
                        ...this.state.membership,
                        planName: membershipData.planname,
                        planID: membershipData.id,
                        startDate: startDate,
                        endDate: endDate,
                        planCost: amountBreakupData.planCost,
                        duration: membershipData.duration,
                        planTax: amountBreakupData.plantax,
                        taxAmount: amountBreakupData.taxAmount,
                        totalAmount: amountBreakupData.grossAmount,
                        discountPercentage: amountBreakupData.discountPercentage,
                        discountAmount: amountBreakupData.discountAmount,
                        disclaimerMessage: membershipData.disclaimer
                    },
                    planLoading: false
                });
            }).catch(error => {
            });
    }

    getPaymentDetail = () => {
        let data = {
            "filter": [
                { "field": "isdefault", "value": 1, "op": "=", "join": "AND" },
            ]
        }
        axios.post(getBuyersUrl(vaultCardList), JSON.stringify(data), getBuyerHeaders()).then((response) => {
            let data = response.data.data
            this.setState({
                ...this.state,
                cardDegit: data[0].cardlastfourdigit,
                payPalID: data[0].paypalid
            })
        }).catch(() => {
        })

    }

    updateDimensions = () => {
        ToggleSideBar();
        this.setState({ windowWidth: window.innerWidth });
    };

    componentWillUnmount() {
        store.dispatch({ type: 'SET_SIDEBAR', payload: false });
        window.removeEventListener('resize', this.updateDimensions);
    }

    couponValueCheck = (event) => {
        let value = event.target.value
        this.setState({
            ...this.state,
            membership: {
                ...this.state.membership,
                couponCode: value
            },
            couponSuccessMessage: !event.target.value ? '' :  this.state.couponSuccessMessage,
            couponLoading: false,
            couponInvalid: false
        })
    }

    membershipAdd = () => {
        const data = {
            "membershipplanid": this.state.membership.planID,
            "startdate": moment().utc().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            "enddate": moment().utc().add(this.state.membership.duration, 'months').endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            "couponcode": this.state.membership.couponCode,
            "termsaccepted": this.state.termsChecked
        }
        this.setState({
            loading: true
        })
        axios.post(getBuyersUrl(memberShipAdd), { "data": data }, getBuyerHeaders()).then((response) => {
            this.setState({
                loading: false,
                successModal: true,
                termsChecked: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                errorMessage: error.response.data.message,
                
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMessage: ''
                    })
                }, 5000)
            });
        })
    }

    closeSuccessModal = () => {
        this.setState({
            successModal: false,
            couponErrorMessage: '',
            couponSuccessMessage: ''
        })
        setUser(this.props.user.id).then(() => {
            pmAutoNavigateToNextScreen(this.props, this.previousProfile, this.props.user, "membershipCompleted");   
        });  
    }


    getAndApplyCouponCode = () => {
        const data = {
            "planid": this.state.membership.planID,
            "couponcode": this.state.membership.couponCode,
            "type": "Membership",
            "vendor": "CTBIDS"
        }
        this.setState({
            couponLoading: true,
            couponInvalid: true
        })
        axios.post(getBuyersUrl(applyCouponCode), { "data": data }, getBuyerHeaders()).then((response) => {
            let couponData = response.data.data;
            this.setState({
                membership: {
                    ...this.state.membership,
                    planCost: couponData.planCost,
                    planTax: couponData.planTax,
                    taxAmount: couponData.taxAmount,
                    totalAmount: couponData.grossAmount,
                    discountPercentage: couponData.discountPercentage,
                    discountAmount: couponData.discountAmount
                }
            })

            if(response.data.data.discountPercentage != null) {
                this.setState({
                    couponLoading: false,
                    couponSuccessMessage: "Coupon Applied Successfully",
                    disableCouponInput: true,
                    couponInvalid: false
                })
            } else {
                this.setState({
                    couponLoading: false,
                    couponErrorMessage: "Invalid Coupon",
                    couponSuccessMessage: '',
                    disableCouponInput: false,
                    couponInvalid: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            couponErrorMessage: '',
                            couponSuccessMessage: ''
                        })
                    }, 5000)
                });
            }
            
        }).catch((error) => {
            this.setState({
                couponInvalid: false
            })
        })
    }

    cancelCouponCode = () => {
        const data = {
            "planid": this.state.membership.planID
        }
        axios.post(getBuyersUrl(applyCouponCode), { "data": data }, getBuyerHeaders()).then((response) => {
            if(response.data.data) {
                let cancelCouponData = response.data.data;
                this.setState({
                    membership: {
                        ...this.state.membership,
                        couponCode: '',
                        planCost: cancelCouponData.planCost,
                        planTax: cancelCouponData.plantax,
                        taxAmount: cancelCouponData.taxAmount,
                        totalAmount: cancelCouponData.grossAmount,
                        discountPercentage: cancelCouponData.discountPercentage,
                        discountAmount: cancelCouponData.discountAmount,
                    },
                    couponErrorMessage: '',
                    couponSuccessMessage: '',
                    disableCouponInput: false,
                    couponLoading: false
                })
            } 
        })
    }

    termsConditionClick = (event) => {
        this.setState({
            termsChecked: event.target.checked
        })
    }

    render() {
        const { membership } = this.state;

        return (
            <div className="d-flex" style={{overflow:'auto', height: '100%'}}>
                {this.state.planLoading ? 
                <div className="large-screen-width">
                    <div className="loader d-flex align-items-center justify-content-center h-100">
                        <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
                    </div>
                </div> : 
                <div className="mt-3" style={{ margin:'auto' }}>
                    <div className="inner-container">
                        <div style={{ padding: '40px', width: '700px' }}>
                            <div className='font-weight-bold'>Subscription Summary</div>
                            <div>
                                <Row className="plan-details">
                                    <Col>Subscription Name:</Col>
                                    <Col className="font-weight-bold" style={{ whiteSpace: 'nowrap' }}>{membership.planName ? membership.planName : '- - -'}</Col>
                                </Row>
                                <Row className="plan-details">
                                    <Col>Effective Date:</Col>
                                    <Col style={{ whiteSpace: 'nowrap' }}>{membership.startDate ? membership.startDate : '- - -'}</Col>
                                </Row>
                                <Row className="plan-details">
                                    <Col>Expiry Date: </Col>
                                    <Col style={{ whiteSpace: 'nowrap' }}> {membership.endDate ? membership.endDate : '- - -'}</Col>
                                </Row>
                                <Row className="plan-details" style={{ marginBottom: "20px" }}>
                                    <div>
                                        <Col><Form.Control size="lg" type="text" className={`${this.state.disableCouponInput ? 'disable-input' : ''}`} id="address1" name="address1" placeholder="Enter Coupon Code" value={membership.couponCode} onChange={(e) => this.couponValueCheck(e)} /></Col>
                                        <span className={`mt-5 font-weight-bold ${this.state.couponSuccessMessage ?  "text-success" : "text-danger" }`} style={{ fontSize: "13px", paddingLeft: "15px" }}>{this.state.couponSuccessMessage ? this.state.couponSuccessMessage : this.state.couponErrorMessage}</span>
                                    </div>
                                    <div className='d-flex'>
                                        <Col><Button variant="" className='save-btn coupon-btn w-100' disabled={this.state.couponLoading || !membership.couponCode || this.state.disableCouponInput} onClick={() => {this.getAndApplyCouponCode()}}>Apply Coupon {this.state.couponLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}</Button></Col>
                                        {this.state.disableCouponInput ?
                                            <div className='coupon-tag' onClick={() => { this.cancelCouponCode() }}>
                                                {this.state.membership.couponCode} Coupon <div className='cancel-coupon'> x </div>
                                            </div> : ''}
                                    </div>
                                </Row>
                            </div>
                            <div className="">
                                <div className='font-weight-bold'>Pricing</div>
                                <Row className="pricing-details">
                                    <Col className="text-secondary"><b>Amount</b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "20px !important" : "7px" }} className="text-secondary"><b style={{ paddingRight: "15%" }}>{membership.planCost ? `$ ${membership.planCost} ` : '- - -'}</b></Col>
                                </Row>
                                <Row className="pricing-details">
                                    <Col className="text-secondary"><b>Tax</b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "20px !important" : "7px" }} className="text-secondary"><b style={{ paddingRight: "15%" }}>{membership.taxAmount ? `$ ${membership.taxAmount}` : '- - -'}</b></Col>
                                </Row>
                                <Row className="pricing-details">
                                    <Col className="text-secondary"><b>Coupon Savings</b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "20px !important" : "7px" }} className="text-secondary"><b style={{ paddingRight: "15%" }}>{membership.discountAmount ? `$ ${membership.discountAmount}` : '- - -'}</b></Col>
                                </Row>
                                <Row className="pricing-details">
                                    <Col className=" text-secondary"><b>Total Amount</b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "20px !important" : "7px" }} className="text-secondary"><b style={{ paddingRight: "15%" }}>{membership.totalAmount ? `$ ${membership.totalAmount}` : '- - -'}</b></Col>
                                </Row>
                            </div>
                            <div className='mt-4' >
                                <Row>
                                    <Col style={{ fontWeight: 'bold' }}>Payment Method :</Col>
                                    <Col style={{ whiteSpace: 'nowrap' }}>{this.state.cardDegit ? ` Card Ending with ${this.state.cardDegit}` : this.state.payPalID ? this.state.payPalID : '- - -'}</Col>
                                </Row>
                            </div>
                            <div className='mt-3 p-3 d-flex align-items-center justify-content-center' style={{ backgroundColor: "#f5f5f5" }}>
                                <div className='d-flex m-0'>
                                    <Form.Check type="checkbox" id="terms" name="terms" label="Click Here To Accept" custom aria-label='termsandcondition' checked={this.state.termsChecked} onChange={(e) => {this.termsConditionClick(e)}} />
                                    <span>&nbsp;</span>
                                    <a className='terms-link' target='_Blank' href='https://image.ctbids.com/buyer/Premier-Subscription-TermsAndConditions-V1.pdf'>Premier Terms and Conditions</a>
                                </div>
                            </div>
                            <div className='mt-3' style={{ fontSize: "10px" }}>
                                {this.disclaimerFn(this.state.membership.disclaimerMessage)}
                            </div>
                            <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                                <Button variant="outline-primary member-cancel-btn" onClick={() => this.goToPlan()} disabled={this.state.loading}>Cancel</Button>
                                <Button variant="" className='save-btn member-save' onClick={() => this.membershipAdd()} disabled={this.state.loading || !this.state.termsChecked || this.state.couponInvalid} >Confirm {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}</Button>
                            </div>
                            <div className='text-center mt-5 text-danger'>{this.state.errorMessage}</div>
                        </div>
                    </div>
                </div>}

                <Modal animation={false} centered show={this.state.successModal} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <div className="body-content">
                            <p style={{textAlign: "center"}}>Your Membership is added successfully!</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center actions mt-5">
                            <Button variant="secondary mx-2" onClick={()=> {this.closeSuccessModal()}}>Close</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

export default connect(state => state)(VipPlan);
