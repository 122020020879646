import { useEffect } from "react";

export const AddAriaLabel = (props) => {

    const addAriaLabel = (id, label) => {
        var element = document.querySelector(`[for="${id}"]`);
        if(element && !element.hasAttribute('aria-label')){
            element.setAttribute("aria-label", label)
        }
    }

    useEffect(() => {
        if (props.loading && Array.isArray(props.config)) {
            props.config.forEach(element => {
                addAriaLabel(element.id, element.label);
            });
        }
    }, [props.loading]);
    
    return null
} 