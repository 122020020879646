import React, {  useState, Component, createRef, useEffect } from "react";
import { connect } from "react-redux";
import { check, sneakpreview } from "shared/images/images";
import store from 'features/store';
import '../auctionsp.scss';
import './pmadvisor.scss';
import pmservice from "shared/services/pmservice";
import ToasterMessage from "shared/toast/toast";
import { isAuthorized, logout } from "shared/settings/auth";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import { Button } from "react-bootstrap";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";

function PMAdvisorfn(props) {
    const [pageloading, setPageloading] = useState(true)
    const toasterMessage = createRef();
    const [questions, setquestions] = useState([])
    const [message, setMessage] = useState('')
    const [qa, setqa] = useState('')

    useEffect(()=>{
        if(isAuthorized()) {
            if(props.user.id) {
                getPMAdvisorList();
            } else {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        } else {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
    }, [props.user.id]);

    useEffect(() => {
        let dataLayerData = {
          'event': 'pm_advisor',
          'buyr_id': props.user.id
        }
        addDataLayer(dataLayerData);
      }, []);

    const getPMAdvisorList = () => {
        pmservice.getContent("Premier Advisor","").then(
            async (res) => {
                let list = res?.data?.data?.[0]?.jsoncontent
                list = JSON.parse(list)
                setquestions(list)
                setPageloading(false)
            }
        ).catch((error) => {
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
            setPageloading(false)
        });
    }

    const AnswerType = (data,index) => {
        switch (data?.type) {
            case "radio":
                return (
                    <div className="radio">
                        {data?.options?.map((item, index) => (
                            <div className={`radio_item`} role={"button"} onClick={()=>{
                                setqa((prevState) => {
                                    return{ 
                                        ...prevState,
                                        [data?.Question]:item
                                    }
                                });
                            }}>  
                                <div className={item == 'Yes' ? `yes_radio_circle` : `no_radio_circle` }>
                                    {qa?.[data?.Question]  == item?
                                    <div className={item == 'Yes' ? `radio_incircle` : `no_radio_incircle `}></div> 
                                    : null}
                                </div> 
                                <div>{item}</div>  {index == 0 ? <span className="ml-4" >OR</span> : ''}
                            </div>
                        ))}
                    </div>
                )
            case "input":
                return (
                    <div>
                        <textarea style={{WebkitAppearance: 'none',MozAppearance: 'none', appearance: 'none',borderRadius: '0',}} className="input" onChange={(event)=>{
                             setqa((prevState) => {
                                return{ 
                                    ...prevState,
                                    [data?.Question]:event.target.value
                                }
                            });

                        }} />
                    </div>
                )
            case "checkbox":
                return (
                    <div >
                         {data?.options?.map((item, index) => (
                            <div className="radio_item" role={"button"} onClick={()=>{
                                setqa((prevState) => {
                                    return{ 
                                        ...prevState,
                                        [data?.Question]:item
                                    }
                                });
                            }}>
                                <div className="checkbox">
                                    {qa?.[data?.Question]  == item?
                                         <img className="check" src={check} />
                                    : null}
                                </div>
                                <div>{item}</div>
                            </div>
                        ))}
                    </div>
                )
            default:
                break;
        }
    }

    const onSubmitFeedback = (questions) => {
        let survey =  questions?.body.map((item,index)=>{
            return({
              [ `Q${ index + 1}`] :{
                Question:item?.Question,
                Answer:qa?.[item?.Question]
              }
            })
        })
        const data = {
            "data":{
                "buyrId": props.user.id,
                "survey": survey
            }
        }
        pmservice.postqa(data).then(
            async (res) => {
                if(res?.data?.status == "success") {
                    // toasterMessage.current.showMessage({ type:"danger", message: "We have received your feedback!" }, 3000, true);
                    setMessage('Thank you! We received your feedback.')
                    setTimeout(() => {
                        setMessage('')
                        // toasterMessage.current.hideMessage(0);
                        props.history.push('/my-account/premier/member-benefits');
                    }, 3000);
                }
            }
        ).catch((error) => {
            
        });
    }

    return (
        <>
            <Helmet>
                <title>CTBids | Premier Advisor</title>
            </Helmet>
            {pageloading ?
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
                </div> :
                <div className="pma" style={{ height: "100%", overflowY: 'scroll', paddingBottom: "10%" }}>
                    <div className="asp_container">
                        <div className="header">
                            <div className="center">
                                <img className="advisor-logo" src={questions?.logo} />
                                <div className="title">
                                    <h1 className="font-weight-bold">{questions.title}</h1>
                                    <div className="head_cont">
                                        <h6 style={{  fontSize: '20px' }}>{questions?.subtitle}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qa w-100">
                            {questions?.body?.map((item, index) => (
                                <div className="qaview">
                                    <div className="ques">
                                        <div className="circle" tyle={{ width:'10%' }}>
                                            <text>{index + 1}</text>
                                        </div>
                                        <div style={{ width:'90%' }}>{item?.Question}</div>
                                    </div>
                                    <div>{AnswerType(item, index)}</div>
                                    {/* <div>{qa?.[item?.Question]}</div> */}
                                </div>
                            ))}
                            <div className="d-flex flex-row justify-content-center mb-3">
                                <Button className="submit" disabled={!qa || (typeof qa == 'object' && Object.values(qa) == '')} onClick={() => { onSubmitFeedback(questions) }}>SUBMIT</Button>
                            </div>
                            <div>
                               <p className="text-center" style={{ color: '#A50050', fontSize: '18px' }}>{message ? <i className="far fa-check-circle h5"></i> : '' } <span style={{ marginTop: '10px'}}> {message}</span></p> 
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
class PMAdvisor extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <PMAdvisorfn {...this.props} />
    }
}

export default connect(state => state)(PMAdvisor);