import { Component, useEffect, useState } from "react";
import { connect } from 'react-redux';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from 'features/components/base/auth-pages/MyAccount/MyAccountMenuList';
import { Button } from "react-bootstrap";
import pmservice from "shared/services/pmservice";
import { logout } from "shared/settings/auth";
import moment from "moment";
import './AutoRenew.scss';

export const AutoRenewfn = (props) => {
  const [loading, setLoading] = useState(true);
  const [autoRenewData, setAutorenewData] = useState([]);
  const [membershipdata, setMembershipdata] = useState({});
  const [autoRenewStatus, setAutorenewStatus] = useState(props?.location?.state);

  useEffect(() => {
   getMembershipDetails();
  }, []);

  useEffect(() => {
    const updateData = async () => {
      try {
        if(props?.location?.autoRenewPage){
          await updateAutoRenewStatus();
        }
        await getContentDetails();
      } catch (error) {
        console.log(error)
      }
    };
  
    if (membershipdata.id) {
      updateData();
    }
  }, [membershipdata.id, autoRenewStatus]);
  

  const getContentDetails = () => {
    pmservice.getContent('AutoRenew', '')
      .then((res) => {
        const response = res.data.data[0];
        const autoRenewRes = JSON.parse(response.jsoncontent);
        setAutorenewData(autoRenewRes);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.status === 401) {
          store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
          logout(props.history, false);
        }
      })
  }
  const getMembershipDetails = () => {
    const data = {
      "field":["enddate","autorenewal","buyerid","id"]
    };
  
    return pmservice.getMembershipDetail(data)
      .then((res) => {
        const membershipData = res.data.data[0];
        setMembershipdata(membershipData);
      })
      .catch((error) => {
      });
  }
  

  const updateAutoRenewStatus = () => {
    const autorenew = {
      "data": {
        "id": membershipdata.id,
        "autorenewal": autoRenewStatus,
      }
    };
  
    return pmservice.updateAutoRenew(autorenew)
      .then((res) => {
        setMembershipdata(prevState => ({
          ...prevState,
          autorenewal: autoRenewStatus
        }));
      })
      .catch((error) => {
      });
  }

  const navigateToHome = () => {
    props.history.push('/estate-sale/list');
  }

  const   replaceTitelContent = (data) => {
    if(data){
      if (data?.includes('Enabled') && membershipdata.autorenewal === 1) {
        return data.replace('Enabled', 'Enabled');
      } else if (data?.includes('Enabled') && membershipdata.autorenewal === 0) {
        return data.replace('Enabled', 'Disabled');
      }
      return data;
    }
  }

  const repleaceDescription = (data) => {
    if(data){
      if (data.includes('ENDDATEPLACEHOLDER')) {
        let dateformat = moment(membershipdata.enddate).format("MMMM DD, YYYY")
        return data.replace('ENDDATEPLACEHOLDER', dateformat);
      }
      return data;
    }
  }

  return (
    <>
      {loading ? <div className="h-100 d-flex align-items-center justify-content-center">
        <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
      </div> :
        <div className="auto-renew">
          <div className="logo-container">
            <img src={autoRenewData?.logo} />
          </div>
          {autoRenewData?.body && (
            <div className="body-container">
              {autoRenewData.body.map((data, index) => (

                <div key={index} className="data-item">
                  <div className="content">
                    <div className="title">{replaceTitelContent(data?.title)}</div>
                    
                      { membershipdata.autorenewal === 0 && data?.disabledescription && data?.disabledescription.map((desc, i) => (
                        <p key={i} className="description">{repleaceDescription(desc)}</p>
                      ))}


                      {membershipdata.autorenewal === 1 && data?.enabledescription && data?.enabledescription.map((desc, i) => (
                        <p key={i} className="description">{repleaceDescription(desc)}</p>
                      ))}
                    

                    {index === 1 && (
                      <div className="logo-button-container">
                        {data?.logo && (
                          <img className="logo" src={data.logo} alt="CTBids Logo" />
                        )}

                        {data?.action && data?.action.type === 'button' && (
                          <Button className="button" onClick={() => navigateToHome()}>
                            {data.action.title}
                          </Button>
                        )}

                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>}
    </>
  );
};

class AutoRenew extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <AutoRenewfn {...this.props} />
  }
}

export default connect(state => state)(AutoRenew);






