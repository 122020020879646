import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import store from "features/store";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import { Button, Spinner } from "react-bootstrap";
import './MemberService.scss';
import pmservice from "shared/services/pmservice";
import { isAuthorized, logout } from "shared/settings/auth";
import { addDataLayer, eCommunication } from "shared/utils/utils";
import { Helmet } from "react-helmet";
import { premierFAQ } from "shared/settings/apiconfig";
import { getBuyerHeaders, getBuyersUrl } from "shared/http";
import axios from "axios";

export const GuranteedDeliveryfn = (props) => {


  const [deliveryGuaranteeData, setDeliveryGuaranteeData] = useState('');
  const [pageloading,  setPageLoading] = useState(true);
  const [contentLoad, setContentLoad] = useState(false);
  const [guranteeData, setGuranteeData] = useState({show: false, dg: ''});
  const [creditData, setCreditData] = useState('');

  useEffect(() => {
    if(isAuthorized()) {
      if (props.user.id) {
        getDeliveryGuranteeData();
        getMemberCredits();
      } else {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, [props.user.id]);

  useEffect(() => {
    let dataLayerData = {
      'event': 'pm_guaranteed_delivery',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  const getDeliveryGuranteeData = () => {
    pmservice.getContent('DeliveryGuarantee', '').then((response) => {
      let dgData = JSON.parse(response.data.data[0].jsoncontent);
      //  deliveryData = JSON.parse(dgData);
       setDeliveryGuaranteeData(dgData);
       setPageLoading(false)
    })
    .catch((error) => {
      if (error?.status === 401) {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
      setPageLoading(false)
    });
  }

  const getMemberCredits = () => {
    const data = {
        "field":["id", "buyerid", "creditsforyear", "creditsused","creditsremaining","creditspurchased"]
    };

    pmservice.getMembershipDetail(data).then((res) => {
      if (res.data.data.length > 0) {
        setCreditData(res.data.data[0]);
      }
    })
    .catch((error) => {
    });
  }

  const textchange = (data) => {
    let replaceContent = data;
    if (data.includes("CREDITPOINTSUSED") && data.includes("CREDITPOINTSAVAILABLE")) {
      replaceContent = data.replace("CREDITPOINTSAVAILABLE", creditData['creditsforyear']).replace("CREDITPOINTSUSED", creditData['creditsremaining'])
        return (<b style={{color: '#00263E', fontSize: "20px"}}>
          {replaceContent}
        </b>)
    } else if(data.includes("CREDITPOINTSAVAILABLE")){
      replaceContent = data.replace("CREDITPOINTSAVAILABLE", creditData['creditsforyear']);
      return replaceContent;
    }
    return replaceContent;
  }

  const showContent = (type) => {
    if (guranteeData.show) {
      setGuranteeData(prevData => ({ ...prevData, show: false, [type]: '' }));
    } else if (type) {
      setContentLoad(true);
      axios.get(getBuyersUrl(`${premierFAQ}${type}`), getBuyerHeaders())
        .then(response => {
          setGuranteeData(prevData => ({ ...prevData, show: true, [type]: response.data }));
          setContentLoad(false);
        }).catch(() => {
          setContentLoad(false);
        })
    }
  };
  
  return (
    <>
    <Helmet>
        <title>CTBids | Delivery Guarantee</title>
      </Helmet>
      {pageloading ?
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
        </div> :
        <div className="memberserivce" style={{ padding: "30px", overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <img className="w-80" src={deliveryGuaranteeData?.logo}  />
            <div className="delivery-title">
                <h1 className="font-weight-bold">{deliveryGuaranteeData.title}</h1>
            </div>
          </div>
          <div className="head_cont mt-3">{deliveryGuaranteeData?.description}</div>
          {deliveryGuaranteeData?.action?.title ? <Button style={{ marginTop: '15px', backgroundColor: '#1D8296', padding: '10px', borderRadius: '10px', border: 'none' }} onClick={()=>window.open(deliveryGuaranteeData?.action?.navigatesTo)}>{deliveryGuaranteeData?.action?.title}</Button> : ''}


          <div className="d-flex flex-column mt-3 mobile-resp">
            {deliveryGuaranteeData?.body?.map((data, index) => (
              <div className="d-flex flex-column mt-3 mobile-wid" key={index} style={{ margin: "10px 0", width: 'calc(80% - 20px)' }}>

                <div className="font-weight-bold fs-20 mt-2" style={{color: "rgb(165, 0, 80)"}}>{data.title}</div>

                {
                  Array.isArray(data?.description) ? 
                    <div className="mt-3"> 
                    {data?.description.map((desc, idx) => (
                        desc?.title ? <><div>
                          <ul>
                            <li>{eCommunication(desc?.title)}</li>
                          </ul>
                          <div style={{ marginLeft: "2.5rem" }}>{desc?.subtitle ? eCommunication(desc?.subtitle) : ''}</div>
                      </div></> : <p key={idx} className="mt-3">{textchange(desc)}</p>
                    ))}</div> : 
                    <p className="mt-3">{eCommunication(data?.description)}</p>
                }

                {
                  data?.colorAndText && 
                    <div style={{ display: 'flex', flexWrap: "wrap" }}>
                      {data?.colorAndText.map((item, idx) => (
                        <>
                          <div key={idx} style={{ display: "flex", alignItems: "center", margin: "5px 0", width: "100%", marginTop: "10px" }}>
                            <div style={{ width: "20px", height: "20px", backgroundColor: item.color, marginRight: "10px" }}></div>
                            <div>{item.title}</div>
                          </div>
                          <div className="mt-3 mb-3">
                            {item.image ? <img style={{ marginLeft: "1.6rem" }} src={item?.image} /> : ''}
                          </div>
                        </>
                      ))}
                    </div>
                }
              </div>
            ))}
          </div>

          <div className="mt-2">
            <ul className="plus">
              <li className="clickable" style={{ fontSize: "16px" }} onClick={() => showContent("dg")}>
                {"+ " + "Frequently Asked Questions (click to expand)"} {contentLoad ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner> : ''}
              </li>
            </ul>
            {
              guranteeData.show ?
                <div className="content">
                  <div className="" dangerouslySetInnerHTML={{ __html: guranteeData.dg }}></div>
                </div> : ''
            }
          </div>
          
        </div>}
    </>
  );
};
class guranteedDelivery extends Component {
  constructor(props) {
      super(props);
      this.buyrId = this.props.user.id
  }

  componentDidMount() {
      store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
      ToggleSideBar();
      window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
      window.removeEventListener('resize', ToggleSideBar);
      removeSideBar();
  }

  render() {
      return <GuranteedDeliveryfn {...this.props}  />
  }
}

export default connect(state => state)(guranteedDelivery);
