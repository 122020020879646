import store from "features/store";
import { Component } from "react"
import { Button, Modal } from "react-bootstrap";


class CommonMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.user.suspensionstatus == "ALL" ? true : false
        }
    }

    closeSuspensionModal = () => {
        store.dispatch({ type: 'TOGGLE_SUSPEND_MODAL', payload: false });
        this.setState({
            show: false,
        })
    }

    render() {
        return (
            <div style={{ backgroundColor: 'red' }}>
                {
                    this.state.showModal ?
                        <Modal centered show={this.props.toggleSuspendModal}>

                            <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button style={{
                                        backgroundColor: "#FFF",
                                        color: "#7D7D7D",
                                        fontSize: "large",
                                        fontWeight: "bold",
                                        borderWidth: 0
                                    }} onClick={() => { this.closeSuspensionModal() }} >X</Button>
                                </div>
                                <div className="text-center p-3 font-weight-bold" style={{ color: '#00263E' }}>
                                 You are currently suspended from bidding on CTBids. Please reach out to <a style={{ color: '#A50050' }} href="tel:(844) 4-BID-HELP">(844) 4-BID-HELP</a> or email <a style={{ color: '#A50050' }} href="mailto:ctbidshelp@caringtransitions.com">ctbidshelp@caringtransitions.com</a> if you have any questions.
                                </div>
                            </Modal.Body>
                        </Modal> : ''
                }
            </div>
        )
    }
}

export default CommonMethods;