import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import '../personal-info/PersonalInfo.scss';

export const HeaderHOC = (props) => {

    return (
        <div>
            {
                !props.user.profilecompleted || !props.user.interestCompleted || !props.user.paymentcompleted || !props.user.membershipCompleted ? 
                <Row className='d-flex align-items-center justify-content-center mb-3 mt-3'>
                <div className='steps-container step-style mb-3'>
                    <div className='steps'>
                        <div className="d-flex flex-column align-items-center justify-content-center w-20">
                            <span className={`circle clickable ${props.user.profilecompleted ? "progress-success" : "progress-failed"}`} onClick={() => props.history.push(`/my-account/my-info/personal-info`)}>
                                <div>
                                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>Your Information</Tooltip>}>
                                        <span>✔</span>
                                    </OverlayTrigger>
                                </div>
                            </span>
                            <div>
                                <span className="font-weight-bold">Your Information</span>
                            </div>
                        </div>
                        <div className="stepper-item d-flex flex-column align-items-center justify-content-center w-20">
                            <div className={`circle ${props.user.interestCompleted ? "progress-success" : "progress-failed"}`} onClick={() => props.history.push(`/my-account/my-info/personal-interests`)}>
                                <div>
                                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>Your Interests</Tooltip>}>
                                        <span>✔</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div>                        
                                <span className="font-weight-bold">Your Interests</span>
                            </div>
                        </div>
                        <div className="stepper-item d-flex flex-column align-items-center justify-content-center w-20">
                            <div className={`circle ${props.user.paymentcompleted ? "progress-success" : "progress-failed"}`} onClick={() => props.history.push(`/my-account/my-info/payment-methods`)}>
                                <div>
                                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>Get Ready To Bid</Tooltip>}>
                                        <span>✔</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div>
                                <span className="font-weight-bold">Get Ready To Bid</span>
                            </div>
                        </div>
                        <div className="stepper-item d-flex flex-column align-items-center justify-content-center w-20">
                            <div className={`circle ${props.user.membershipCompleted ? "progress-success" : "progress-failed"}`} onClick={() => props.user.membershipCompleted ? props.history.push(`/my-account/premier/member-benefits`) : props.history.push(`/my-account/join-premier-member`)}>
                                <div>
                                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>Become a Premier Member</Tooltip>}>
                                        <span>✔</span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div>
                                <span className="font-weight-bold">Become a Premier Member</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center w-20" >
                            <span style={{ cursor: 'inherit' }} className={`circle ${props.user.profilecompleted && props.user.interestCompleted && props.user.paymentcompleted && props.user.membershipCompleted ? "progress-success" : "progress-failed"}`} >
                                <div>
                                    <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>All Done. Thank you!</Tooltip>}>
                                        <span>✔</span>
                                    </OverlayTrigger>
                                </div>
                            </span>
                            <div>
                                <span className="font-weight-bold">All Done. Thank you!</span>
                            </div>
                        </div>
                    </div>
                </div>
                </Row> : ''
            }            
        </div>

    )
}