import store from "features/store";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { new_image_thumbnail, sneakpreview, timetable } from "shared/images/images";
import { ItemsService, LocationService } from 'shared/services';
import { isAuthorized, logout } from "shared/settings/auth";
import { MyAccountMenuList, removeSideBar, ToggleSideBar } from "../base/auth-pages/MyAccount/MyAccountMenuList";

function AuctionSneakPeekSaleitemfn(props) {
    const [itemList, setItemList] = useState([]);
    const [watchList, setWatchList] = useState([]);
    const [locImg, setlocImg] = useState("");
    const [locationDetails, setlocationDetails] = useState({});
    const [sale, setsale] = useState((props?.history?.location?.state?.sale));
    const [pageLoading, setPageLoading] = useState(true);
    const [locationId, setLocationId] = useState((props?.location?.state?.sale?.locationid));
    const [saleId, setSaleId] = useState(props.match.params.saleId);
    const [disableWatch, setDisableWatch] = useState(false);
    const [itemDetail, setItemDetail] = useState((props?.history?.location?.state?.itemDetails));

    useEffect(() => {
        loading()
        return () => {

        }
    }, [])
    const loading = async () => {
        await getSaleItemList(props.match.params.saleId)
         await getLocData()
         setPageLoading(false)
    }


   
    const getLocData = async () => {
        await LocationService.getLocationDetails(sale?.locationid ? sale?.locationid : locationId, { data: {} }).then(
            location => {
                setlocationDetails(location.data.data[0])
            }
        ).catch((error) => {
            // TODO: Error message handle
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        });
        const locationImageData = { "filter": [{ "field": "locationid", "value": sale?.locationid, "op": "=", "join": "AND" }] };
        await LocationService.getLocationImagesList(locationImageData).then(
            locationImage => {
                setlocImg(locationImage?.data?.data)
            }
        ).catch((error) => {
            // TODO: Error message handle
        });
    }
    const getSaleItemList = async () => {
        const itemData = {
            "sort": [{ "field": "starttime", "direction": "asc" }],
            "filter": [
                { "field": "saleid", "value": props.match.params.saleId, "op": "=", "join": "AND", },
                { "field": "isdeleted", "value": 0, "op": "=", "join": "AND" },
                { "field": "isdisplayimage", "value": 1, "op": "=", "join": "AND" }
            ],
            "page":{"size":10}
        };
        ItemsService.getSneekPeakList(itemData).then(
            async (itemList) => {
                const temp = itemList.data.data;
                setItemList(temp)
                await getWatchList(temp)
            }
        ).catch((error) => {
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        });
    }
    const getWatchList = (itemLists) => {
        if (isAuthorized()) {
            let itemId = [];
            itemLists.forEach(item => {
                itemId.push(item.id ? item.id : item.itemid);
            });
            if (itemLists.length > 0) {
                const data = {
                    "sort": [{ "field": "id", "direction": "desc" }],
                    "filter": [{ "field": "itemid", "value": [itemId], "op": "IN", "join": "AND" }],
                    "field": ["itemid"],
                    "page": { "size": itemLists.length + 1 }
                };
                ItemsService.getWatchListItem(data).then(
                    response => {
                        const watch = response.data.data;
                        setWatchList(watch)
                        if (watch.length > 0) {
                            itemLists = itemLists.map((item) => {
                                return ({
                                    ...item,
                                    iswatch: watch.filter(i => i?.itemid === item?.id).length > 0
                                })
                            })
                            setItemList(itemLists)
                        }
                    }
                ).catch(error => {
                });
            }
        } else {
        }
    }
    const watching = (item, index) => {
        setDisableWatch(true);
        if (!isAuthorized()) {
            return
        }

        if (item?.iswatch) {
            const removeData = {
                "data": {
                    "itemIds": [item?.id]
                }
            };
            ItemsService.removeFromWatch(removeData).then(
                response => {
                    loading();
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        } else {
            const addData = {
                "data": [
                    {
                        "itemid": item?.id
                    }
                ]
            }
            ItemsService.addToWatch(addData).then(
                response => {
                    loading();
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        }

    }

    const navigateToItem = (item) => {
        props.history.push({
            pathname: `/my-account/premier/auction-sneak-peak-item/${props.match.params.saleId}/item/${item?.id}`, state: {
                item,
                itemId: item.id,
                saleId: saleId,
                sale: sale,
                locationData: locationDetails,
                locationImg: locImg
            }
        });
    }

    const moreSaleForLocation = () => {
        const location = locationDetails;
        if (location.id) {
            const locationUrl = location.locationcode ? `/estate-sales/${location.locationstatecode}/${location.locationcode}` : `/estate-sales/${location.locationstatecode}`;
            props.history.push(locationUrl);
        }
    }

    if (pageLoading)
        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div>)
    return (
        <div className="asp"  style={{ height: "100%"}}>
               {props.user.suspensionstatus === "ALL" ?
                <div className="w-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
                    You are currently suspended from bidding on CTBids. Please reach out to <a  style={{ color: '#A50050' }} href="tel:(844) 4-BID-HELP" >(844) 4-BID-HELP</a>  or email <a style={{ color: '#A50050' }} href="mailto:ctbidshelp@caringtransitions.com">ctbidshelp@caringtransitions.com</a> if you have any questions.
                    </div>
                </div> :
            <div className="aspitem_container"  style={{ overflow: 'scroll', height: "100%", width: "100%"}}>
            <div className="asp_container" >
                    <div className="center">
                        <img className="timetable" src={timetable} />
                        <div className="title d-flex justify-content-center flex-column">
                            <h1 className="font-weight-bold ">Auction Sneak Peek</h1>
                        </div>
                    </div>
                    <div className="header">
                        <div style={{ width: "100%" }}>
                            <div className="head_cont mt-3">
                                <div>You are among the first to preview these auction items from {locationDetails.title ? locationDetails.title : sale.locationtitle }.</div>
                                <div>Item description and pictures may be updated prior to the Live Auction tentatively starting {itemDetail?.salestarttime ? moment(itemDetail?.salestarttime).format("MMMM DD") : moment(sale?.starttime).format("MMMM DD")}.</div>
                            </div>
                            <button className="sneak-btn-sale" onClick={() => {
                                props.history.push({ pathname: "/my-account/premier/auction-sneak-peak" });
                            }}>
                                <div>Return To Sneak Peek Listings</div>
                            </button>
                        </div>
                        <div className="item_left mt-3">
                          {locationDetails &&  <div class="caring-transition">
                                <div class="l_header">Caring Transitions Location</div>
                                <div class="body-content">
                                    <div class="user-details d-flex align-items-center clickable">
                                        {
                                            locImg.length > 0 && React.Children.toArray(locImg.filter(x => x.name === 'profile').map((x, index) =>
                                                <img src={x.url} className="phone-icon" alt="phone-icons"></img>
                                            ))
                                        }
                                        <div class="name clickable"><div>{locationDetails?.contactname}</div>
                                            <div>
                                                <div class="light-color city-alignment city-new-site-alignment">{locationDetails?.city}</div>
                                                <div class="website city-website-alignment city-new-site-alignment"
                                                    onClick={() => {
                                                        window.open(locationDetails?.website, '');
                                                    }}>
                                                    <i class="fas fa-globe"></i>
                                                    <span>Visit Site </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-sale" onClick={() => moreSaleForLocation()}>
                                        <i className="fas fa-link"></i> <span className="clickable click-more-sale">Please click to check for more sales</span>
                                    </div>
                                    <div className="mt-3 navy-text">
                                        We know you're excited about upcoming auctions, we are too. Until this auction is live, we will not be able to answer any questions or provide additional information regarding these items. Auction dates and items are subject to change.
                                    </div>

                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="itemlist">
                        {itemList?.map((item, index) => (
                            <div key={`items_${index}`} className="sp-card d-flex flex-column align-items-center justify-content-center clickable">
                                <div className={"item_card"}>
                                    <div onClick={() => { navigateToItem(item) }}>
                                        <div style={{ height: '250px' }} className="d-flex align-items-center justify-content-center">
                                            <img className="item-image" src={item.displayimageurl ? item.displayimageurl : item.displayimage ? item.displayimage : new_image_thumbnail} alt="item"></img>
                                        </div>
                                        {/* {
                                        item.displayimageurl || item.displayimage ?
                                            : <div className="d-flex flex-column align-item-center justify-content-center">
                                                <img alt="item-image" src={new_image_thumbnail} className="item-image"></img>
                                            </div>
                                    } */}
                                        <div className="item_text">
                                            <div className="item-card-title">{item?.title}</div>
                                        </div>
                                    </div>
                                    <div className={`clickable ${item?.iswatch ? "sp-watching" : "watch"}`}>
                                        <button onClick={() => {
                                            watching(item, index)
                                        }} disabled={disableWatch}>
                                            <div>Watch </div>
                                            <i className=" fa fa-eye" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center mrbt-2 sneak-item-resp">
                    <img className="logobid" src={sneakpreview} />
                </div>
            </div >}
        </div>
    )
}

class AuctionSneakPeekSaleitem extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }
  
    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }
  
    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }
  
    render() {
        return <AuctionSneakPeekSaleitemfn {...this.props}  />
    }
  }
  
  export default connect(state => state)(AuctionSneakPeekSaleitem);