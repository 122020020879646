import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import pmservice from "shared/services/pmservice";
import { ToggleSideBar, removeSideBar, MyAccountMenuList, } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import store from "features/store";

const CTBidsThankYouFn = (props) => {
    const [CTBidsThankYouDetail, getCTBidsThankyou] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getThankYouPage = () => {
            pmservice.getContent("CTBidsThankYou", "").then((res) => {
                let data = res.data.data[0].jsoncontent
                let thankyoudetails = JSON.parse(data)
                getCTBidsThankyou(thankyoudetails)
                setLoading(false)
            })

        }
        getThankYouPage()
    }, [])

    const repleaceText = (data) => {

        let subtitle = data.split('#')

        return subtitle.map((data, index) => {
            if (index === 1) {
                return ''; 
            }    
            else if (index == 2) {
                return <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { props.history.push('/estate-sale/list') }}>
                    {data}
                </span>
            }
            else {
                return data
            }
        })
    }

    return (
        <>
            {loading ? <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> :
                <div  style={{ padding: "30px", overflow: "auto" }}>
                    {CTBidsThankYouDetail && <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={CTBidsThankYouDetail?.logo} alt="" style={{ width: '100px' }} />
                            <div  className="ml-4 font-weight-bold" style={{ float: 'right',}}>
                                <div style={{ fontSize: '30px' }}>{CTBidsThankYouDetail?.title}</div>
                                <div style={{ color: '#A50050', fontSize:'20px'  }}>{repleaceText(CTBidsThankYouDetail?.subTitle)}</div>
                            </div>
                        </div>
                       
                        <div className="mt-5">
                            {
                                CTBidsThankYouDetail?.body[0]?.description.map((data, index) => {
                                    return <div className="mt-3" key={index}>{data}</div>
                                })
                            }
                        </div>

                        <div className="mt-3" style={{ textAlign: 'center', }}>
                            <img src={CTBidsThankYouDetail?.body[0]?.ctbidslogo} alt="" style={{ width: '300px',  }} />
                        </div>
                    </div>}
                </div>}
        </>
    )

}

class CTBidsThankYou extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }
    render() {
        return <CTBidsThankYouFn {...this.props} />
    }
}

export default connect(state => state)(CTBidsThankYou)