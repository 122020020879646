import { PAYPAL_SECRET, PayPalClientId as CLIENT_ID, 
    PAYPAL_BASE_URL } from "shared/settings/config";

// generate paypal alphanumeric customer id
/* append this before userId in paypal sdk and vault related apis */
export const paypalCustomerId = (userId) => 'bidder_' + userId;

// call the create order method
export async function createOrder(clientMetaDataId) {
    const response = await fetch(`${PAYPAL_BASE_URL}/v2/checkout/orders`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await generateAccessToken()}`,
            "PayPal-Request-Id": new Array(23).join().replace(/(.|$)/g, function(){return ((Math.random()*36)|0).toString(36);}),
            "PAYPAL-CLIENT-METADATA-ID": clientMetaDataId
        },
        body: JSON.stringify({
            intent: 'AUTHORIZE',
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: '1.01'
                    }
                }
            ]
        }),
    });
    const data = await response.json();
    return data.id;
}

// generate access token
export async function generateAccessToken() {
    const auth = Buffer.from(CLIENT_ID + ":" + PAYPAL_SECRET).toString("base64");
    const response = await fetch(`${PAYPAL_BASE_URL}/v1/oauth2/token`, {
        method: "post",
        body: "grant_type=client_credentials",
        headers: {
            Authorization: `Basic ${auth}`,
        },
    });
    const data = await response.json();
    return data.access_token;
}

// generate client token
export async function generateClientToken(userId) {
    const accessToken = await generateAccessToken();
    const response = await fetch(`${PAYPAL_BASE_URL}/v1/identity/generate-token`, {
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Accept-Language": "en_US",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            customer_id: paypalCustomerId(userId)
        })
    });
    const data = await response.json();
    return data.client_token;
}

// authorize api with get payment token api
// used in paypal sdk, can be used when order status is CREATED
/* authorize an order and store payment method in valut(explicitly not show in response), can be 
    retrieved using payment-token api */
export async function authorizeOrder(orderId, clientMetaDataId) {
    const response = await (await fetch(`${PAYPAL_BASE_URL}/v2/checkout/orders/${orderId}/authorize`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await generateAccessToken()}`,
            "PayPal-Request-Id": new Array(23).join().replace(/(.|$)/g, () => ((Math.random()*36)|0).toString(36)),
            "PAYPAL-CLIENT-METADATA-ID": clientMetaDataId
        }
    })).json();
    return response.status === 'COMPLETED' ? { status: true, data: response } : { status: false };
}